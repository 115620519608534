import React from "react";
import styled, { withTheme } from "styled-components";

const Conteiner = styled.div`
  background-color: rgb(246, 246, 246);
  width: 100%;
  height: 100%;
  display: inline-flex;
  align-items: center;
  align-content: space-between;
  padding: 10px 0px 10px 0px;
`;

const ButtonConteiner = styled.div`
  padding-left: 50px;
`;

const ItemButton = styled.button`
  background-color: rgb(
    ${(props) => (props.selected ? props.theme.primary : "246,246,246")}
  );
  padding: 10px 10px 10px 10px;
  color: ${(props) => (props.selected ? "white" : "black")};
  font-family: ${(props) => props.theme.font};
  font-size: 40px;
  border: none;
  cursor: pointer;

  &:hover {
    background-color: rgb(${(props) => props.theme.primary});
    color: white;
  }

  @media only screen and (max-width: 768px) {
    font-size: 20px;
  }
`;

const ProjectsPanel = ({ categories, onCategorySelect }) => {


  return (
    <Conteiner>
      {[{name: "Todos"}, ...categories].map((item, index) => (
        <ButtonConteiner>
          <ItemButton key={index} onClick={()=> {
            onCategorySelect(item.name);
          }} >{item.name}</ItemButton>
        </ButtonConteiner>
      ))}
    </Conteiner>
  );
};

export default withTheme(ProjectsPanel);
