import React, { useState } from "react";
import styled, { withTheme } from "styled-components";
import Swal from "sweetalert2";
import { setNewContact } from "../Data/Http/portafolio";

const Conteiner = styled.div`
  background-color: rgb(${(props) => props.theme.secondary});
  font-family: ${(props) => props.theme.font};
  color: white;
  padding-top: 30px;
`;

const TitleConteiner = styled.div`
  text-align: center;
`;

const FormConteiner = styled.div`
  width: 50%;
  height: 100%;
  margin: 0 auto;
  padding-bottom: 60px;
`;
const InputGroup = styled.div`
  margin-top: 30px;

  & > label {
    font-size: 20px;
  }
`;

const Input = styled.input`
  width: 100%;
  font-family: ${(props) => props.theme.font};
  font-size: 25px;
  outline: none;
  border-color: ${(props) => (props.hasError ? "red" : "black")};
  color: ${(props) => (props.hasError ? "red" : "black")};
`;

const TextArea = styled.textarea`
  width: 100%;
  font-family: ${(props) => props.theme.font};
  font-size: 25px;
  outline: none;
  border-color: ${(props) => (props.hasError ? "red" : "black")};
  color: ${(props) => (props.hasError ? "red" : "black")};
`;

const InfoConteiner = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: space-between;

  & > label {
    font-size: 20px;
  }
`;

const Button = styled.button`
  background-color: rgb(${(props) => props.theme.primary});
  color: white;
  font-size: 20px;
  padding: 10px 10px 10px 10px;
  border: none;
  outline: none;

  &:hover {
    cursor: pointer;
  }

  @media only screen and (max-width: 768px) {
    font-size: 14px;
  }
`;

const Contact = () => {
  //states:
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [hasError, setHasErros] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const sendValues = async (event) => {
    event.preventDefault();
    const obj = {
      title: name,
      email,
      phone,
      description: message,
    };

    if (formIsValid(obj)) {
      setIsLoading(true);
      //send message
      const result = await setNewContact(obj);

      if (result.status === 200) {
        setIsLoading(false);
        Swal.fire({
          icon: "success",
          text: "Muchas Gracias.. Me contactare contigo lo mas pronto posible",
        });
        setName("");
        setPhone("");
        setEmail("");
        setMessage("");
      } else {
        Swal.fire({
          icon: "error",
          text:
            "Upps.. Ha ocurrido un error al enviar el mensaje. Por Favor Intentelo mas tarde",
        });
      }
    } else {
      Swal.fire({
        icon: "warning",
        text: "Por favor complete los campos requeridos.",
      });
    }
  };

  const formIsValid = (obj) => {
    debugger;
    let valid = false;

    valid = obj.title !== "" && obj.email !== "" && obj.description !== "";

    setHasErros(!valid);
    return valid;
  };

  return (
    <Conteiner id="Contact">
      <FormConteiner>
        <TitleConteiner>
          <h2>¿Deseas trabajar conmigo?</h2>
          <p>Dime que tienes en mente. Estoy atento a nuevas ideas.</p>
        </TitleConteiner>

        <form
          name="contact"
          method="post"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          onSubmit={sendValues}
        >
          <InputGroup>
            <label>Nombre</label>
            <Input
              onChange={(event) => setName(event.target.value)}
              value={name}
              hasError={name === "" && hasError}
            />
          </InputGroup>

          <InputGroup>
            <label>Correo electronico</label>
            <Input
              onChange={(event) => setEmail(event.target.value)}
              value={email}
              hasError={email === "" && hasError}
            />
          </InputGroup>

          <InputGroup>
            <label>Teléfono</label>
            <Input
              onChange={(event) => setPhone(event.target.value)}
              value={phone}
            />
          </InputGroup>

          <InputGroup>
            <label>Mensaje</label>
            <TextArea
              rows={10}
              value={message}
              onChange={(event) => setMessage(event.target.value)}
              hasError={message === "" && hasError}
            />
          </InputGroup>

          <input type="hidden" name="form-name" value="contact" />

          <InfoConteiner>
            <label>Telefono: 849-650-9989</label>
            <Button type="submit" disabled={isLoading}>
              {!isLoading ? "Enviar Mensaje" : "Enviando Datos.."}
            </Button>
          </InfoConteiner>
        </form>
      </FormConteiner>
    </Conteiner>
  );
};

export default withTheme(Contact);
