import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import {withRouter} from 'react-router-dom'
import {ButtonOutlineCircle} from '../ButtonOutline';
import {CompleteSourcePath} from '../../utils/ApiSources';



//STYLES:
const Card= styled.div`
box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
transition: 0.3s;
width: 300px;
border-radius: 8px;

&:hover{
box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}
`
const Image= styled.img`
width: 100%;
height: 50%;
border-radius: 8px 8px 0 0;
`

const CardContainer= styled.div`
padding: 10px 16px 17px;
`
const AuthorInfo= styled.p`
color: gray;
font-size: 12px;
margin-bottom: 5px;
`

const PostCard= ({postId,url,title, description,image, author,date,...props})=> {

    
    const {history}= props;

    function goToPost(){
        history.push(`Blog/Posts/${url}`);
    }

    return (
        <Card>
            <Image src={CompleteSourcePath(image)}></Image>
            <CardContainer>
                <h3>{title}</h3>
                <AuthorInfo>Por {author} Publicado el {moment(date).format('dd/MM/yyyy h:mm a')}</AuthorInfo>
                <p style={{marginBottom: '15px'}}>{description}</p>
                <ButtonOutlineCircle onClick={goToPost}>Leer Mas</ButtonOutlineCircle>
            </CardContainer>
        </Card>
    )
}


export default withRouter(PostCard);