import React, { Fragment, useState, useEffect } from "react";
import styled, { withTheme } from "styled-components";
import ProjectItem from "./ProjectItem";
import ProjectsPanel from "./ProjectsPanel";
import {apiUrl} from '../../Data/Http/index'
//Data:
import {getProjects,getCategories} from '../../Data/Http/portafolio';

const ProjectsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media only screen and (max-width: 768px){
    grid-template-columns: 1fr;
  }
`;

const Conteiner = styled.div`
  
`;

const NoProjectsConteiner = styled.div`
  font-family: ${(props) => props.theme.font};
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;
`;

const Projects = ({setShowModal, setProjectId}) => {
  
  const [projects, setProjects]= useState([]);
  const [projectCategories, setProjectCategories]= useState([]);
  const [projectFilter, setProjectsFilters] = useState([]);

  // Get the lasted prjects:
  useEffect(()=> {
    async function fetch(){
      const {data}= await getProjects(true);
      setProjects(data);
      setProjectsFilters(data);
    }
    fetch()
  },[])
  // get the project categories:
  useEffect(()=> {
    async function fetch(){
      const {data}= await getCategories();
      setProjectCategories(data);
    }
    fetch()
  },[])

  function filterCategories(category) {
    
    let actualProjects = [...projects];

    if (category !== "Todos") {
      
      let newList= [];
      for (let project = 0; project < actualProjects.length; project++) {
        const element = actualProjects[project].categories.filter(s=> s.name=== category)[0];
        if (element)
          newList.push(actualProjects[project]);
      }

      actualProjects= newList;
    }

    setProjectsFilters(actualProjects);
  }

  function setImagePath(url){

    return apiUrl.slice(0,-1) + url;
  }

  return (
    <Fragment>
      <Conteiner>
        <ProjectsPanel
          categories={projectCategories}
          onCategorySelect={filterCategories}
        />
        {projectFilter.length > 0 ? (
          <ProjectsContainer>
            {projectFilter.map((item) => (
              <ProjectItem
                key={item.id}
                title={item.title}
                subTitle={item.subTitle}
                textBody={item.description}
                background={setImagePath(item.Image.url)}
                onSelect={() => {
                  setProjectId(item.id);
                  setShowModal(true);
                }}
              />
            ))}
          </ProjectsContainer>
        ) : (
          <NoProjectsConteiner>
            <h2>No hay proyectos en esta categoria</h2>
          </NoProjectsConteiner>
        )}
      </Conteiner>
    </Fragment>
  );
};

export default withTheme(Projects);
