import React from "react";
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
  EmailShareButton,
  EmailIcon
} from "react-share";

const ShareButtons = (props) => {
  return (
    <div>
        <h4>Compartir Publicación: </h4>
      <FacebookShareButton
        title="Compartir a Facebook"
        url={props.url}
        style={{ padding: "5px" }}
      >
        <FacebookIcon bgStyle={{ fill: "black" }} size={42} />
      </FacebookShareButton>
      <TwitterShareButton
        title="Compartir en Twitter"
        url={props.url}
        style={{ padding: "5px" }}
      >
        <TwitterIcon bgStyle={{ fill: "black" }} size={42} />
      </TwitterShareButton>
      <LinkedinShareButton
        title="Compartir en Linkedin"
        url={props.url}
        style={{ padding: "5px" }}
      >
        <LinkedinIcon bgStyle={{ fill: "black" }} size={42} />
      </LinkedinShareButton>
      <EmailShareButton
        title="Enviar por Correo"
        url={props.url}
        style={{ padding: "5px" }}
      >
        <EmailIcon bgStyle={{ fill: "black" }} size={42} />
      </EmailShareButton>
    </div>
  );
};

export default ShareButtons;
