import React, {useEffect, useState, useContext} from 'react';
import LoadingMode from '../../contexts/LoadingMode'
import styled,{withTheme} from 'styled-components';
import {withRouter} from 'react-router-dom'
import moment from 'moment';
import Markdown from 'markdown-to-jsx';

import {CompleteSourcePath} from '../../utils/ApiSources';

//Components
import {PostAvatar} from '../../components/Avatar';
import ShareButtons from '../../components/Blog/ShareButtons';
import PostComments from '../../components/Blog/PostComments';

//Data
import {getPostFullData} from '../../Data/Http/blog'


const Container= styled.div`
padding: 60px 60px;
`

const AuthorImageContainer= styled.div`
display: inline-flex;
align-items: center;
position: relative;
margin: 0 0 0 120px;
top: -60px;
font-family: ${props=> props.theme.font};

&> div{
    padding: 12px;
}

&> div> p{
    color: gray;
}

&> div> p> span{
    color: rgb(${props=> props.theme.primary})
}
`

const ImageContainer = styled.img`
width: 100%;
height: 600px;
`

const CotentConteiner= styled.div`
margin: 0 0 0 120px;
font-family: ${props=> props.theme.font};
font-size: 20px;
`

const PostDetails= (props)=> {

    const {match: {params}}= props;
    const [postData, setPostData]= useState({
        Image: {},
        author: {},
        content: ""
    });
    const {setLoading}= useContext(LoadingMode);


    useEffect(()=> {
        async function fetch(){
            setLoading(true);
            const {data}= await getPostFullData(params.postUrl);
            setPostData(data[0]);
            setLoading(false);
        }
        fetch();
    },[params.postUrl,setLoading])

    return (
        <Container>
            <ImageContainer src={CompleteSourcePath(postData.Image.url)}/>
            <AuthorImageContainer>
             <PostAvatar ImageProfile={"http://localhost:1337/uploads/Ruben_A_Batista_Foto_2x2_b7826a98af.JPG"}/>
            <div>
                <p style={{float: 'left'}}><i class="fas fa-user"></i> Por: <span>{postData.author.firstname + ' '+ postData.author.lastname}.</span> Publicado el {moment(postData.published_at).format('dd/MM/yyyy h:mm a')}</p>
                <p style={{float: 'right', marginLeft: '20px'}}><i class="fas fa-eye"></i>  {postData.viewCount} Vistas</p>
            </div>
            </AuthorImageContainer>

            <CotentConteiner>
                <h2 style={{marginBottom: '20px'}}>{postData.title}</h2>

                <div style={{marginBottom: '120px'}}>
                    <Markdown options={{forceBlock: true}}>{postData.content}</Markdown>
                </div>
                {/* Sección de Compartir */}
                <ShareButtons url={CompleteSourcePath(params.postUrl)}/>
                {/* Sección de Comentarios */}
                <PostComments postId={postData.id}/>
            </CotentConteiner>
        </Container>
    )
}


export default withTheme(withRouter(PostDetails));