import http from './index';

/**
 * Get the base data:
 */
export async function getResumeInfo(){


    const query= `query getConfiguration {
        configuration {
          description
          descriptiontitle
          name
          subtitle
          cv {
            url
          }
          image {
            url
          }
        }
      }`
        
    var result= await http.post('',{
        query: query
    });


    if (result.status===200){
        return result.data.data.configuration;
    }

}

export async function getSkills(){

    const query= `query MyQuery {
        allSkills(orderBy: _createdAt_ASC) {
          name
          level
          id
        }
      }`;

    var result= await http.post('',{
        query: query
    });
    
    if(result.status=== 200){
        return result.data.data.allSkills;
    }
    else{
        return [];
    }
}

export async function getCategories(){
    return await http.get('/categories')
}

export async function getProjects(lasted){
    return lasted? await http.get('/projects?_limit=4&_sort=id:DESC'): await http.get('/projects');
}

export async function getExperiencies(){
    return await http.get('/works-histories');
}

export async function setNewContact(data){
    return await http.post('/contacts',data);
}