import http from './index';



export async function getLastedPost(){

    return await http.get('/posts?_limit=3&_sort=id:DESC')
}

export async function getPostOfPage(page){

    return await http.get('/posts?_limit=9&_sort=id:DESC')
}

export async function getPostFullData(postId){
    return await http.get(`/posts?URL=${postId}`)
}

export async function getCommnetsOfPost(postId){
    return await http.get(`/post-comments/byProjectId/${postId}`);
}

export async function createNewComment(data){
    return await http.post('/post-comments',data);
}

export async function updateComment(data){
    return await http.put('/post-comments',data);
}