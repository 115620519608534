import React from 'react';
import PostDetails from './Views/Blog/PostDetails';
import HomeView from './Views/Home';

export const Routes= [
{
    path: '/',
    main: ()=> <HomeView />,
    exact: true
},
{
    path: '/Blog/Posts/:postUrl',
    main: ()=> <PostDetails />,
    exact: true
}]
