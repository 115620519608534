import React, { useRef, Fragment } from "react";

//Components
import TopMenu from '../components/Menu/TopMenu';
import Home from "../components/Home";
import Section from "../components/Section";
import About from "../components/About";
import Experience from "../components/Experience";
import Projects from "../components/projects/Projects";
import Skills from "../components/skils/Skils";
import BlogLastedPost from "../components/BlogLastedPost";
import Contact from "../components/Contact";

import OpenMenu from "../components/Menu/OpenMenu";

const HomeView = ({ IsLoading }) => {
  const menuRef = useRef();

  return (
    <Fragment>
      <TopMenu />
      {!IsLoading && (
        <OpenMenu
          OnMenuClick={() => (menuRef.current.style.display = "grid")}
        />
      )}
      <Home />
      <Section title="Acerca de" icon="fas fa-user" hasMargin id="About">
        <About />
      </Section>
      <Section title="Experiencia" icon="fas fa-chart-line" id="Experiencie">
        <Experience />
      </Section>
      <Section title="Proyectos" icon="fas fa-folder" id="Projects">
        <Projects />
      </Section>
      <Section title="Habilidades" icon="fas fa-chart-bar" id="Skills">
        <Skills />
      </Section>
      <Section title="Blog" icon="fas fa-pager" id="Blog">
        <BlogLastedPost />
      </Section>
      <Section title="Contacto" icon="fas fa-envelope" id="Contact">
        <Contact />
      </Section>
    </Fragment>
  );
};

export default HomeView;
