import React, { useState, useEffect } from "react";
import styled, { withTheme } from "styled-components";
import { Link } from "react-scroll";

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  font-family: ${(props) => props.theme.font};
  justify-content: space-between;
  align-items: center;
  transition: 1.06s;
  padding: ${(props) => (props.isTop ? "5px" : "40px")} 100px;
  background-color: ${(props) => (props.isTop ? "white" : "none")};
  z-index: 1222323;
  box-shadow: ${(props) => (props.isTop ? "0 0 20px rgb(0 0 0 / 9%);" : "")};

  & > .logoLink {
    position: relative;
    font-weight: 700;
    color: ${(props) => (props.isTop ? "black" : "white")};
    text-decoration: none;
    font-size: 2em;
    text-transform: uppercase;
    transition: 0.6s;
  }
`;
const MenuItems = styled.ul`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  & li {
    position: relative;
    list-style: none;
    color: ${(props) => (props.isTop ? "black" : "white")};
  }

  & li:hover{
      color: rgb(10, 158, 255);
      cursor: pointer;
  }

  & li a {
    position: relative;
    margin: 0 15px;
    text-decoration: none;
    letter-spacing: 2px;
    font-weight: 500px;
    transition: 1.6s;
  }

  & li a:hover {
    color: "#019AFF";
  }
`;

function TopMenu(props) {
  const [isTop, setTop] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 120) {
        setTop(true);
      } else {
        setTop(false);
      }
    };

    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => window.removeEventListener("scroll", handleScroll);
  });

  return (
    <Container isTop={isTop}>
      <Link className="logoLink" href="#">
        LOGO
      </Link>
      <MenuItems isTop={isTop}>
        <li>
          <Link
            activeClass={"topMenu_Active"}
            to="Home"
            smooth={true}
            duration={1000}
            spy={true}
          >
            Inicio
          </Link>
        </li>
        <li>
          <Link
            activeClass={"topMenu_Active"}
            to="About"
            smooth={true}
            duration={1000}
            spy={true}
          >
            Acerca de
          </Link>
        </li>
        <li>
          <Link
            activeClass={"topMenu_Active"}
            to="Experiencie"
            smooth={true}
            duration={1000}
            spy={true}
          >
            Experiencia
          </Link>
        </li>
        <li>
          <Link
            activeClass={"topMenu_Active"}
            to="Projects"
            smooth={true}
            duration={1000}
            spy={true}
          >
            Proyectos
          </Link>
        </li>
        <li>
          <Link
            activeClass={"topMenu_Active"}
            to="Skills"
            smooth={true}
            duration={1000}
            spy={true}
          >
            Habilidades
          </Link>
        </li>
        <li>
          <Link
            activeClass={"topMenu_Active"}
            to="Blog"
            smooth={true}
            duration={1000}
            spy={true}
          >
            Blog
          </Link>
        </li>
        <li>
          <Link
            activeClass={"topMenu_Active"}
            to="Contact"
            smooth={true}
            duration={1000}
            spy={true}
          >
            Contacto
          </Link>
        </li>
      </MenuItems>
    </Container>
  );
}

export default withTheme(TopMenu);
