import React, { Fragment, useEffect, useState } from "react";
import styled from "styled-components";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Transition } from "react-spring/renderprops";
import Loading from "./components/Loading";
import { Routes } from "./Routes";
import Footer from "./components/Footer";

//Context:
import { ThemeProvider } from "styled-components";
import LoadingMode from "./contexts/LoadingMode";

// Libraly transitions
import Aos from "aos";
import "aos/dist/aos.css";

//Global Theme:
const theme = {
  primary: "1, 154, 255",
  secondary: "2, 78, 128",
  font: "fontScore",
};

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 800px 1fr 1fr;

  @media only screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const Body = styled.div`
  grid-column: 2/3;
`;

function App() {
  const [IsLoading, setLoading] = useState(true);

  useEffect(() => {
    Aos.init({ duration: 2000 });
    LoadingPage();
  }, []);

  function LoadingPage() {
    const interval = setInterval(() => {
      if (document.readyState === "complete") {
        setTimeout(() => {
          setLoading(false);
          clearInterval(interval);
        }, 1000);
      }
    });
  }

  return (
    <Fragment>
      <Transition //set fade out transition
        items={IsLoading ? Loading : null}
        from={{ opacity: 1 }}
        enter={{ opacity: 1 }}
        leave={{ opacity: 0 }}
      >
        {(Item) => Item && ((props) => <Item fade={props.opacity} />)}
      </Transition>
      {/* {!IsLoading && <BackTop />} */}
      <Container>
        <ThemeProvider theme={theme}>
          <LoadingMode.Provider value={{isLoading: IsLoading, setLoading: setLoading}}>
            <Body>
            <Router>
              <Switch>
                {Routes.map((item, index) => (
                  <Route
                    path={item.path}
                    component={item.main}
                    exact={item.exact}
                    key={index}
                  />
                ))}
              </Switch>
            </Router>
            <Footer />
            </Body>
          </LoadingMode.Provider>
        </ThemeProvider>
      </Container>
    </Fragment>
  );
}

export default App;
