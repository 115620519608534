import axios from 'axios';
import dotenv from 'dotenv';

//set the enviorment variables:

 dotenv.config();

export const apiUrl= "https://graphql.datocms.com/";
const TOKEN= '49bd409c397d545baa4f8a72a902c7' //process.env.API_TOKEN;

const http= axios.create({
    baseURL: apiUrl,
    headers: {
        Authorization: `bearer ${TOKEN}`
    }
})

export default http;