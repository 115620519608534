import styled from 'styled-components';
import React from 'react';


const Conteiner= styled.div`
position: fixed;
cursor: pointer;
opacity: 0.8;
z-index: 99; 
outline: none; 
font-size: 25px;
left: 25px;
top: 5px;
display: none;

@media only screen and (max-width: 768px){
    display: block;
  }
`

const Button= styled.button`
background-color: transparent;
border: none;
`


const OpenMenu= ({OnMenuClick})=> {



    return (
        <Conteiner>
            <span>
            <Button  onClick={OnMenuClick}>
                <i className="fas fa-bars"></i>
            </Button>
             </span>
        </Conteiner>
    )
}

export default OpenMenu;