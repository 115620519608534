import React, { useState, useEffect,useCallback } from "react";
import styled from "styled-components";

import { ButtonOutline } from "../ButtonOutline";
import Comment from "./Comment";

//Data
import { getCommnetsOfPost, createNewComment } from "../../Data/Http/blog";

const Container = styled.div`
  margin: 10px;
  width: 70%;
`;

const TextArea = styled.textarea`
  width: 100%;
  font-family: ${(props) => props.theme.font};
  font-size: 16px;
  outline: none;
  border-color: ${(props) => (props.hasError ? "red" : "black")};
  color: ${(props) => (props.hasError ? "red" : "black")};
  resize: none;
`;

const PostComments = ({ postId }) => {
  const [commentValue, setValue] = useState("");
  const [currentComments, setCurrentComments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const fetch= useCallback(async ()=> {
    if (postId) {
      const { data } = await getCommnetsOfPost(postId);
      setCurrentComments(data);
    }
  },[postId])


  useEffect(() => {
    fetch();
  }, [postId,fetch]);

  async function saveComment() {
    setIsLoading(true);
    const obj = {
      comment: commentValue,
      authorName: "Ruben Batista",
      post: {
        id: postId,
      },
    };

    const result = await createNewComment(obj);

    if (result.status === 200) {
      setValue("");
      await fetch();
    }

    setIsLoading(false);
  }

  return (
    <Container>
      <h2>Comentarios ({currentComments.length}): </h2>
      <br />

      <TextArea
        rows={8}
        onChange={(event) => setValue(event.target.value)}
        value={commentValue}
      />
      <div
        style={{
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "flex-end",
        }}
      >
        <ButtonOutline
          borderColor="32, 33, 33"
          style={{ marginRight: "10px" }}
          onClick={() => setValue("")}
        >
          <i className="fas fa-eraser"></i>
        </ButtonOutline>
        <ButtonOutline
          borderColor="32, 33, 33"
          onClick={saveComment}
          disabled={isLoading || commentValue===''}
        >
          {isLoading ? "Enviando..." : "Publicar Comentario"}
        </ButtonOutline>
      </div>

      <Comment data={currentComments} />
    </Container>
  );
};

export default PostComments;
