import styled from 'styled-components';

export const Avatar = styled.div`
  background-image: url(${(props) => props.ImageProfile});
  background-size: cover;
  vertical-align: middle;
  width: 217px;
  margin: 0 auto;
  height: 217px;
  border-radius: 50%;
  border-color: rgb(${(props) => props.theme.primary});
  border-style: solid;
  margin-bottom: 10px;

  @media only screen and (max-width: 768px) {
    width: 150px;
    height: 150px;
  }
`;

export const PostAvatar= styled(Avatar)`
width: 140px;
height: 140px;
margin: 0 0 0 0;
`