import React,{useEffect,useState} from "react";
import styled, { withTheme } from "styled-components";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import {getExperiencies} from '../Data/Http/portafolio'

import Bg from '../assets/images/ExperiencieBg.jpg';

const Conteiner = styled.div`
  background-color: rgb(${(props) => props.theme.secondary});

  &::after {
    background-image: url(${(props) => props.Image});
    opacity: 0.5;
  }
`;

const Experience = ({theme}) => {

  const [list, setList]= useState([]);

useEffect(()=> {
  async function fetch(){
    const {data}= await getExperiencies();
    setList(data);
  }
  fetch();
},[])
  
function getYearText(dateStart, dateEnd){

  let text ='';
  if (dateStart){
    text= `${new Date(dateStart).getFullYear()}`
  }

  if (dateEnd){
    text+= `-${new Date(dateEnd).getFullYear()}`
  }

  return text;
}

  return (
    <Conteiner Image={Bg}>
      <VerticalTimeline>
        {list.map((item,index)=> (
          <VerticalTimelineElement
          key={index}
          contentStyle={{backgroundColor: 'rgb(3, 126, 206)', color: 'white', fontFamily: theme.font}}
          contentArrowStyle={{ borderRight: '7px solid  rgb(3, 126, 206)' }}
          date={getYearText(item.DateStart,item.DateEnd)}
          >
            <label>{item.placeName}</label><br/>
            <label>{item.title}</label>
            <p>
             {item.description}
            </p>
          </VerticalTimelineElement>
        ))}
       
      </VerticalTimeline>
    </Conteiner>
  );
};

export default withTheme(Experience);
