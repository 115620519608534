import React, { Fragment } from "react";
import styled, { withTheme } from "styled-components";

const Conteiner = styled.div`
  position: relative;
  &:hover #TextConteiner {
    height: 100%;
  }
`;

const Image = styled.img`
  display: block;
  width: 100%;
  height: 100%;
`;
const DescriptionContainer = styled.div`
  position: absolute;
  height: 0;
  width: 100%;
  top: 0;
  background-color: rgb(17, 123, 191);
  overflow: hidden;
  line-height: 30px;
  transition: 0.5s ease;
  color: white;
  font-family: ${(props) => props.theme.font};
  opacity: 0.9;
  text-align: center;
  font-size: 25px;

  @media only screen and (max-width: 768px) {
    font-size: 18px;
  }
`;
const Text = styled.div`
  padding: 50px;

  @media only screen and (max-width: 768px) {
    padding: 25px;
  }
`;

const Button = styled.button`
  padding: 10px 10px 10px 10px;
  border: 2px solid;
  background-color: rgb(17, 123, 191);
  color: white;
  cursor: pointer;

  &:hover {
    background-color: white;
    color: black;
    border: none;
  }
`;

const ProjectItem = ({ title, subTitle, textBody, background, onSelect}) => {


  return (
    <Fragment>
      <Conteiner>
        <Image src={background} alt={title} />
        <DescriptionContainer id="TextConteiner">
          <Text>
            <h2>{title}</h2>
            <br />
            <h3>{subTitle}</h3>
            <br />
            <p>{textBody}</p>
          </Text>
          <Button onClick={onSelect}>MAS INFORMACIÓN</Button>
        </DescriptionContainer>
      </Conteiner>
    </Fragment>
  );
};

export default withTheme(ProjectItem);
