import React from "react";
import styled from "styled-components";
import moment from 'moment';

const Container = styled.div`
  padding: 10px;
  margin: 10px;
`;

const Details= styled.div`
  border-bottom: 1px gray solid;
`

const Info = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Content = styled.div`
  padding: 30px;
`;

const Comment = ({ data }) => {
  return (
    <Container>
      {data.map((item, index) => (
        <Details key={index}>
          <Info>
             <h4>{item.authorName}</h4>
            <h4>{moment(item.published_at).format('dd/MM/yyyy')}</h4>
            <h4>{item.likeCount? item.likeCount: 0} Me gusta</h4>
          </Info>
          <Content>
            <p>
              {item.comment}
            </p>
          </Content>
        </Details>
      ))}
    </Container>
  );
};

export default Comment;
