import styled from 'styled-components';


export const ButtonOutline= styled.button`
background-color: ${props=> props.bgColor? props.bgColor: 'white'};
  color: black;
  font-size: 20px;
  padding: 10px 30px 10px 30px;
  border: rgb(${props=> props.borderColor? (props.borderColor): (props.theme.primary)}) solid 2px;
  transition: all ease 400ms;
  
  &:hover:not([disabled]) {
    cursor: pointer;
    background-color: rgb(${props=> props.borderColor? props.borderColor: 'white'});
    color: ${props=> props.borderColor? 'white': 'black'};
    
  }
  &:disabled{
    background-color: #ECECEC;
    border-color: #ECECEC;
    
  }
`
export const ButtonOutlineCircle= styled(ButtonOutline)`
border-radius: 16px;
padding: 5px 10px;
font-size: 16px;
`