import React, {useEffect,useState} from 'react';
import styled from 'styled-components';
import PostCard from './Blog/PostCard';

import {getLastedPost} from '../Data/Http/blog';

const Container= styled.div`
font-family:  ${props=> props.theme.font};
background-color: rgb(250,250,250);
padding: 40px 150px;
`


const BlogLastedPost= (props)=> {

   const [post, setPost]= useState([]);

    useEffect(()=> {
        async function fetch(){
            const {data}= await getLastedPost();
            setPost(data);
        }
        fetch();
    },[])

    return (
        <Container>
            <h2 style={{textAlign: "center"}}>Mis Ultimas publicaciones</h2>
            {post.map((item)=> (
                <PostCard
                url={item.URL} 
                key={item.id}
                postId={item.id}
                title={item.title}
                author={item.author.firstname + ' ' + item.author.lastname}
                image={item.Image.url}
                description={item.shortDescription}      
                />
            ))}
        </Container>
    )
}


export default BlogLastedPost;