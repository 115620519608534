import React from "react";
import styled from "styled-components";
import { RingLoader } from "react-spinners";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  font-size: 50px;
  color: rgb(1, 154, 255);
  position: fixed;
  background-color: white;
  z-index: 1;
  overflow: auto;
  opacity: ${(props) => props.fade};
`;

const Loading = ({ fade }) => {
  return (
    <Container fade={fade}>
      <RingLoader color={"#019AFF"} />
    </Container>
  );
};

export default Loading;
