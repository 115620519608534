import React, { useEffect } from 'react';
import styled, { withTheme } from 'styled-components';
import ScrollIcon from './ScrollIcon';
import Particles from 'react-particles-js';



const Container= styled.div`
background-image: url(${props=> props.bgPath});
background-color: ${props=> props.BgColor};
background-size: cover;
background-repeat: no-repeat;
height: 100vh;
width: 100%;
grid-column: 1/-1;
font-family: ${props=> props.theme.font};
text-align: center;
`;

const TitleContainer= styled.div`
height: 600px;
width: 100%;
font-family: ${props=> props.theme.font};
font-size: 65px;
margin: 0 auto;
padding-top: 40vh;
color: white;

@media only screen and (max-width: 768px) {
    font-size: 25px;
  }

&> p{
    font-size: 25px;

    @media only screen and (max-width: 768px) {
    font-size: 12px;
  }
}
`;

const particlesOptions={
    interactivity: {
        detect_on: "canvas",
        events: {
          onhover: {
            enable: true,
            mode: "grab"
          },
          onclick: {
            enable: true,
            mode: "push"
          },
          resize: true
        }
    }
}


 function Home(){

    useEffect(()=> {
        
    })

    return (
        <Container BgColor="black" id="Home">
            {/* <TopMenu /> */}
            <Particles className="particles" params={particlesOptions} />
            <TitleContainer>
                <label>Rubén <strong style={{color: '#019AFF'}}>Batista</strong></label>
                <br/>
                <p style={{margin: '12px'}}>Desarrollador de Software</p>
                
            </TitleContainer>
            <ScrollIcon />
        </Container>
    )
}

export default withTheme(Home);