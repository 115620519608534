import React from "react";
import styled, { withTheme } from "styled-components";
import {ReactComponent as FacebookIcon} from '../assets/svg/Facebook.svg';
import {ReactComponent as GithubIcon} from '../assets/svg/Github.svg';
import {ReactComponent as InstegramIcon} from '../assets/svg/Instagram.svg';
import {ReactComponent as LinkedinIcon} from '../assets/svg/Linkedin.svg';


const Conteiner = styled.div`
  padding-top: 30px;
  padding-bottom: 100px;
  background-color: rgb(1, 54, 86);
  font-family: ${(props) => props.theme.font};
  color: rgb(143, 143, 143);
  width: 100%;

  & > p {
    font-size: 18px;
    text-align: center;
  }
`;

const IconConteiner = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 50px);
  grid-template-rows: 50px;
  gap: 5px;
  justify-content: center;
`;

const Footer = () => {
  return (
    <Conteiner>
      <IconConteiner>
        <a href="https://www.google.com.do">
            <FacebookIcon/>
        </a>
        <a href="https://www.google.com.do">
            <InstegramIcon/>
        </a>
        <a href="https://www.linkedin.com/in/ruben-batista-a68a22129">
            <LinkedinIcon/>
        </a>
        <a href="https://github.com/DevRuben97">
            <GithubIcon/>
        </a>
      </IconConteiner>
      <br/>
      <p>RUBEN ALEXANDER BATISTA SANTOS</p>
    </Conteiner>
  );
};

export default withTheme(Footer);
