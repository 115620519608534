import React,{useEffect, useState} from "react";
import styled, { withTheme } from "styled-components";
import {ButtonOutline} from './ButtonOutline';
import {Avatar} from './Avatar';
import {getResumeInfo} from '../Data/Http/portafolio';

const Conteiner= styled.div`
font-family:  ${props=> props.theme.font};
background-color: rgb(250,250,250);
padding: 30px 30px 0px 30px;
min-width: 100%;
`;

const Text= styled.p`
text-align: center;
font-size: 25px;
font-weight: ${props=> props.isbold? 'bold': 'normal'};
`

const DescriptionConteiner= styled.div`
width: 100%;
border-left-color: rgb(${props=> props.theme.primary});
border-left-style: solid;
font-family: ${props=> props.theme.font};
font-weight: bold;
font-size: 40px;

@media only screen and (max-width: 768px) {
    font-size: 20px;
  }

&> p{
    text-align: left;
    margin-left: 15px;
    
}
`
const DescriptionText= styled.div`
margin-top: 30px;
margin-bottom: 40px;

&> p{
    font-size: 32px;
    text-align: left;

    @media only screen and (max-width: 768px) {
      font-size: 14px;
  }
}
`

const About = () => {

  const [resume,setResume]= useState({});
  const [image, setImage]= useState('');

  useEffect(()=> {
    async function fetch(){
      const data= await getResumeInfo();
      setResume(data);
     let photo= data.image.url;
     setImage(photo);
    }
    fetch();
  },[])

  function downloandCv(){
    const source= resume.cv.url;
    const element= document.createElement('a');
    element.href= source;
    element.click();
    element.remove();
  }

  return (
    <Conteiner>
         <Avatar ImageProfile={image} />
        <Text isbold>{resume.name}</Text>
          <Text>{resume.subtitle}</Text>
         <br/>
         <DescriptionConteiner>
                <p>
                    {resume.descriptionTitle}
                </p>
         </DescriptionConteiner>

         <DescriptionText>
             <p>
                {resume.description}
             </p>
         </DescriptionText>

         <div style={{alignContent: 'center', display: 'flex', justifyContent: 'center', marginBottom: '12px'}}>
          <ButtonOutline onClick={downloandCv}>Descargar Cv <span>&darr;</span></ButtonOutline>
         </div>
    </Conteiner>
  );
};

export default withTheme(About);
