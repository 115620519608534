import React, { Fragment,useEffect, useState } from "react";
import styled, { withTheme } from "styled-components";
import {Spring,config} from 'react-spring/renderprops'

//Data
import {getSkills} from '../../Data/Http/portafolio';

const Conteiner = styled.div`
  margin: 30px 30px 30px 30px;
  background-color: rgb(246, 246, 246);
  display: grid;
  grid-template-columns: minmax(120px, auto) 1fr;

  gap: 10px;
`;

const BarConteiner = styled.div`
  background-color: rgb(198, 198, 198);
  width: 100%;
  text-align: right;
  font-family: ${(props) => props.theme.font};
  font-size: 20px;
`;
const SkillBar = styled.div`
  background-color: rgb(${(props) => props.theme.primary});
  width: ${(props) => props.width+"%"};
  height: 100%;
  color: white;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
`;
const TitleContainer = styled.div`
  background-color: rgb(${(props) => props.theme.secondary});
  text-align: center;
  font-family: ${(props) => props.theme.font};
  color: white;
  padding: 15px 15px 15px 15px;
`;

const Skills = () => {

  const [skillsData, setSkills]= useState([]);


  useEffect(()=> {
    async function fetch(){
      const data= await getSkills();
      setSkills(data);
    }
    fetch();
  },[])

  return (
    <Conteiner>
      {skillsData.map((item, index) => (
        <Fragment>
          <TitleContainer>{item.name}</TitleContainer>
          <BarConteiner>
            <Spring
            from={{width: 0}}
            to={{width: item.level}}
            reset
            config={{
              ...config.default,
              duration: 2000
            }}
            >
              {props=> (
                <SkillBar width={props.width}>
                {`${Number.parseInt(props.width)}%`}
              </SkillBar>
              )}
            </Spring>
          </BarConteiner>
        </Fragment>
      ))}
    </Conteiner>
  );
};
export default withTheme(Skills);
