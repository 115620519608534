import React from "react";
import styled from "styled-components";
import { withTheme } from "styled-components";

//Styled Components:
const HeaderContainer = styled.div`
  background-color: rgb(${(props) => props.theme.primary});
  font-size: 40px;
  font-family: ${(props) => props.theme.font};
  padding-top: 30px;
  padding-bottom: 30px;
  color: white;
  width: 100%;

  @media only screen and (max-width: 768px){
    font-size: 25px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
`;
const SectionContainer = styled.div`
  grid-column: 1/-1;
`;

const TextContainer = styled.div`
  margin-left: 40px;
`;
const Section = ({ icon, title, children, hasMargin, id }) => {
  return (
    <SectionContainer id={id} data-aos="fade-up">
      <HeaderContainer>
        <TextContainer>
          <label>
            {icon && <i className={icon}></i>} {title.toUpperCase()}
          </label>
        </TextContainer>
      </HeaderContainer>
      <div>{children}</div>
    </SectionContainer>
  );
};

export default withTheme(Section);
